<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('externalTraining.hand_note_circular') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <searchBasic :search="search" @search-data="searchIt"/>
                  <b-col lg="12" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('externalTraining.hand_note_circular') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(training_date)="data">
                                          <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(view)="data">
                                            <router-link :to="`hand-note-entry-list?circularMemoNo=${data.item.circular_memo_no}&batchNo=${data.item.batch_no}`" :title="$t('externalTraining.hand_note') + ' ' + $t('globalTrans.list')" :class="'btn_table_action table_action_view'"><i class="fas fa-address-card"></i></router-link>
                                        </template>
                                        <!-- <template v-slot:cell(action)="data">
                                            <b-button variant="iq-bg-info" size="sm" @click="remove(data.item)" ><i class="far fa-check-square m-0"></i></b-button>
                                        </template> -->
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="$t('externalTraining.hand_note') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerScheduleList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import searchBasic from './../../../component/searchBasic'
export default {
    mixins: [ModalBaseMasterList],
    components: {
      searchBasic
    },
    data () {
        return {
          circularLoading: false,
          search: {
            org_id: 0,
            fiscal_year_id: 0,
            circular_memo_no: 0,
            training_type_id: '',
            training_category_id: '',
            training_title_id: '',
            batch_no: 0,
            course_module_id: ''
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          trainerNameList: [],
          trainerList: [],
          circularList: [],
          allBatchListData: [],
          trainingTypeList: [],
          batchList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          loadingData: false,
          showData: false
        }
    },
    computed: {
      fiscalYearList: function () {
          return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0).map(obj => {
              if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
              } else {
              return { value: obj.value, text: obj.text_en }
              }
          })
        },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
            { label: this.$t('elearning_config.organization'), class: 'text-left' },
            { label: this.$t('elearning_config.training_title'), class: 'text-left' },
            { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
            { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
            { label: this.$t('externalTraining.hand_note_info'), class: 'text-left' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'circular_memo_no' },
              { key: 'batch_bn' },
              { key: 'view' }
            ]
          } else {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'batch' },
              { key: 'view' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.traineeTranerObj.trainerEvaluationList
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
      // this.search.fiscal_year_id = this.getCurrentFiscalYearId(this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0))
      this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
      searchIt (search) {
        this.search = search
      },
      getCurrentFiscalYearId (fiscalYearList) {
        if (fiscalYearList.length === 0) {
          return
        }
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        /** Define the year position  */
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        let currentFiscalYearId = 0
        fiscalYearList.forEach(element => {
          if (element.text_en.indexOf(yearStr) === yearPosition) {
            currentFiscalYearId = element.value
          }
        })
        return currentFiscalYearId
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      // loadData () {
      //     const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      //     this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      //     RestApi.getData(trainingElearningServiceBaseUrl, trainerScheduleList, params).then(response => {
      //       if (response.success) {
      //         this.$store.dispatch('setList', this.getCustomDataList(response.data))
      //         this.allData = response.data.data
      //       }
      //       this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      //     })
      // },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, trainerScheduleList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          return Object.assign({}, item, orgData, batchData, fiscalYearData, trainingTitleData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
